/* src/pages/PrivacyPolicy.css */

.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
    color: #333;
    text-align: left; /* Ensure text is left-justified */
  }
  
  .privacy-policy-container h2,
  .privacy-policy-container h4 {
    margin-top: 20px;
    color: #444;
    text-align: left; /* Ensure headings are left-justified */
  }
  
  .privacy-policy-container p {
    margin-bottom: 15px;
    text-align: left; /* Ensure paragraphs are left-justified */
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    margin-left: 20px;
    text-align: left; /* Ensure list items are left-justified */
  }
  
  .privacy-policy-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    text-align: left; /* Ensure table content is left-justified */
  }
  
  .privacy-policy-container th,
  .privacy-policy-container td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .privacy-policy-container th {
    background-color: #f4f4f4;
    text-align: left; /* Ensure table headers are left-justified */
  }
  
  .privacy-policy-container td {
    vertical-align: top;
    text-align: left; /* Ensure table cells are left-justified */
  }