/* src/Guide.css */

.guide-container {
    text-align: center;
    padding: 20px;
  }
  
  .guide-image {
    width: 100%;
    max-width: 400px;
    margin: 20px 0;
  }
  
  .download-section {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .download-section h2 {
    margin-bottom: 10px;
  }
  
  .download-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .download-section ul li {
    margin: 10px 0;
  }
  
  .download-section ul li a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
  }
  
  .download-section ul li a:hover {
    text-decoration: underline;
  }