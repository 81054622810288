/* src/App.css */

.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 150px;
  pointer-events: none;
  margin-bottom: 20px;
}

.App-header nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.App-header nav ul li {
  margin: 0 10px;
}

.App-header nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.App-header nav ul li a:hover {
  text-decoration: underline;
}

main {
  flex: 1;
  padding: 20px;
}

footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
  text-align: center;
}

footer a {
  color: #61dafb;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}